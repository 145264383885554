import { StaticImage } from "gatsby-plugin-image";
import React, { useContext, useRef, useEffect } from "react";
import "./Logistics.scss";

import SteveSvg from "../../../images/svg/arrastre-stevedoring-2.svg"
import LoadSvg from "../../../images/svg/loading.svg"
import WHSvg from "../../../images/svg/warehouse.svg"
import CargoSvg from "../../../images/svg/cargo-delivery.svg"
import TMSvg from "../../../images/svg/terminal-management.svg"
import ITSvg from "../../../images/svg/it-services.svg"
import ServiceContext from "../../../contexts/ServiceContext";

const Logistics = () => {
  const { service } = useContext(ServiceContext);
  const steveRef = useRef(null);
  const loadRef = useRef(null);
  const storeRef = useRef(null);
  const cargoRef = useRef(null);
  const terminalRef = useRef(null);
  const portRef = useRef(null);
  const itRef = useRef(null);

  const switchService = () => {
    switch(service) {
      case 'steve':
        return steveRef;
      case 'load':
        return loadRef;
      case 'store':
        return storeRef;
      case 'cargo':
        return cargoRef;
      case 'terminal':
        return terminalRef;
      case 'port':
        return portRef;
      case 'it':
        return itRef;
      default:
        return 'foo';
    }
  }

  // alert(service)

  useEffect(() => {
    if(!service) {

    } else {
      switchService().current?.scrollIntoView({behavior: 'smooth'});
    }
  });

  return (
    <>
      <div className="logistics-con">
      <p className="title">Logistics</p>
     
        <div className="d-flex flex-row service-ch-land">
        <div style={{ position: 'absolute', marginTop: '-13em' }} ref={steveRef}></div>
        <div className="logistics-left">
            <div className="logistics">
              <StaticImage
                  className="ch-img"
                  src="../../../images/stevedoring.png"
                  width={400}
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="about us"
                />

              <div className="text" style={{ paddingBottom: "3em" }}>
                <div className="d-flex flex-row">
               
                </div>
                <p className="label">
                  <img src={SteveSvg} width={40} alt={SteveSvg} />
                  <span>
                    Stevedoring & Arrastre
                  </span>
                </p>
                <p>
                  With over 30 years experience in the handling of bulk cargo,
                  Subic Grain's methods not only ensures efficient discharging
                  of client's raw materials guaranteed via a 99.5% throughout
                  promise coupled by the turnover of any excess cargo acquired
                  during discharge operations, but also prioritises the vessel's
                  laytime stay within the SBMA's waters by accomplishing the task
                  at hand with the shortest amount of time possible.
                </p>
                <br/>
              </div>
            </div>
          </div>
        
        </div>

        <div className="d-flex flex-row service-ch-sea">
        <div style={{ position: 'absolute', marginTop: '-13em' }} ref={loadRef}></div>
       <div className="logistics-right">
            <div className="logistics loading">
              <StaticImage
                  className="ch-img"
                  src="../../../images/loading-truck.png"
                  width={400}
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="about us"
                />

              <div className="text">
                <p className="label">
                  <img src={LoadSvg} width={40} alt={LoadSvg} />
                  <span>
                   Loading
                  </span>
                </p>
                <p>
                  Subic Grain's loading services ensures the efficient and effective
                  loading of cargo unto the client's trucks, be it via 50kgs bags or
                  in bulk. In continuously seeking out ways to streamline our loading
                  operations, we make sure that trucks spend the minimal amount of
                  time possible in our facility whilst enjoying a first come, first
                  served basis in loading priority.
                </p>
              </div>

            
            </div>
          </div>
        </div>

        <div className="d-flex flex-row warehousing">
        <div style={{ position: 'absolute', marginTop: '-15em' }} ref={storeRef}></div>
          <div className="logistics-left">
            <div className="logistics transit-storage">
              <StaticImage
                  className="ch-img"
                  style={{ marginTop: "-7.5em" }}
                  src="../../../images/transit-storage.png"
                  width={400}
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="about us"
                />

              <div className="text" style={{ paddingTop: "2em" }}>
                  <p className="label">
                    <img src={WHSvg} width={40} alt={WHSvg} />
                    <span>
                      Transit Storage
                    </span>
                  </p>
                <p>
                  Subic Grain's Services ensures that consignees have ample
                  storage space for their in-transit cargo. Thus, the Leyte 
                  Wharf facility can hold as much as 220,000 metric tonnes at any given
                  time in form of 15 silos (9,500 metric tonnes each) and Flat Warehouse
                  space (100,000 metric tonnes total).
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-row service-ch-sea">
        <div style={{ position: 'absolute', marginTop: '-13em' }} ref={cargoRef}></div>
          <div className="logistics-right">
            <div className="logistics cargo">
              <StaticImage
                  className="ch-img"
                  src="../../../images/cargo-ship-2.jpeg"
                  width={400}
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="about us"
                />

              <div className="text">
                <p className="label" style={{ paddingTop: "2em" }}>
                    <img src={CargoSvg} width={40} alt={CargoSvg} />
                    <span>
                      Cargo Delivery
                    </span>
                  </p>
                <p className="desc" style={{ color: "#fff" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                sed do eiusmod tempor incididunt ut labore et dolore magna 
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
                ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                sed do eiusmod tempor incididunt ut labore et dolore magna 
                aliqua.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-row service-ch-land">
        <div style={{ position: 'absolute', marginTop: '-13em' }} ref={terminalRef}></div>
          <div className="logistics-left">
            <div className="logistics terminal d-flex">
              <StaticImage
                  className="ch-img"
                  src="../../../images/port-terminal.png"
                  width={400}
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="about us"
                />

              <div className="text" style={{ paddingBottom: "3em" }}>
                  <p className="label">
                    <img src={TMSvg} width={40} alt={TMSvg} />
                    <span>
                      Terminal Management
                    </span>
                  </p>
                <p>
                  We make sure that any facility that we engage 
                  is able to live up to its utmost potential. 
                </p>
                <br/>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-row service-ch-sea">
        <div style={{ position: 'absolute', marginTop: '-13em' }} ref={itRef}></div>
          <div className="logistics-right">
            <div className="logistics aesi">
              <StaticImage
                  className="ch-img"
                  src="../../../images/aesi.png"
                  width={300}
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="about us"
                  style={{
                    marginTop: "2em",
                    marginLeft: "8em"
                  }}
                />

              <div className="text">
                  <p className="label">
                    <img src={ITSvg} width={40} alt={ITSvg} />
                    <span>
                      IT Services
                    </span>
                  </p>
                <p>
                  AESi Automation Solutions is brought to you by 
                  Accur8 Enterprise Solutions Inc. that automates 
                  and improves the operational efficiency of your 
                  port, terminal, cargo handling, warehouse, 
                  inventory tracking, payroll, and accounting.
                </p>
                <br/>
                <p>Visit <a href="https://www.aesiph.com" target="_blank">https://www.aesiph.com/</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Logistics;
